import React from "react";
import routes from "src/core/common/routes";
import {makeProductsPage} from "src/core/pages/productsPagesFactory";
import {
  saveHome,
  getHomeServerSideProps,
  getHomeStaticProps,
} from "src/server/preloader/homePage";
import ProductListSchema from "src/core/seo/components/ProductListSchema";
import * as Sentry from "@sentry/nextjs";
import {getTags} from "src/server/preloader/utils";
import useThemeConfig from "src/themes/useThemeConfig";
import useSite from "src/core/sites/hooks/useSite";
import {isMobile} from "src/core/common/utils";
import QRCodeOnboarding from "src/integrations/tymberQRCode/QRCodeOnboarding";
import useCustomHome from "src/integrations/tymberQRCode/useCustomHome";

function ThemedHomePage(props) {
  const config = useThemeConfig();
  const ThemedProductsPage = config.pages.HomePage;

  return <ThemedProductsPage {...props} />;
}

const Home = makeProductsPage(() => ({pathname: routes.home}), ThemedHomePage);
const ProductReaderPage = makeProductsPage(
  () => ({
    pathname: routes.home,
  }),
  QRCodeOnboarding
);

function HomePage(props) {
  const home = props.serverProps ? props.serverProps.home : props.dataProps.home;
  saveHome(home);

  const [displayCustomHome] = useCustomHome();
  const site = useSite();
  const options = site.getOptions();

  if (displayCustomHome && isMobile() && options.qrCodeOnboardingEnabled()) {
    return <ProductReaderPage hideFooter {...props} />;
  }

  return (
    <div>
      <ProductListSchema />
      <Home />
    </div>
  );
}

export async function ssr_getServerSideProps(context) {
  Sentry.setTags(getTags("getServerSideProps", context));

  return getHomeServerSideProps(context);
}

export async function isr_getStaticProps(context) {
  Sentry.setTags(getTags("getStaticProps", context));

  return getHomeStaticProps(context);
}
export async function isr_getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}

export default HomePage;
